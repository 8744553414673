import s from './CompatibleIndoorUnit.module.scss'
import Button from "src/components/common/Buttons/Button";

const CompatibleIndoorUnit = ({ title, units, cta1, cta2 }) => {
    const CTA = (cta) => <a className="button uppercase-unset" rel="noopener" {...cta}>
        {cta.text}
    </a>
    return <div className="compatible-indoor-unit">
        <h3 className={s.heading}>{title}</h3>
        {(!!cta1 || !!cta2) && <div className={s.buttonWrapper}>
            {!!cta1 && <CTA {...cta1}/>}
            {!!cta2 && <CTA {...cta2}/>}
        </div>}
        <div className={s.unitWrapper}>
            {units.map((u, i) => {
                const { title, content, image, cta } = u.fields
                return <div key={i} className={s.unitItem}>
                    <figure className='mb0'>
                        <img src={image?.url} alt={image?.label || title} className={s?.itemImage} />
                    </figure>
                    <div className={s.itemContent}>
                        <h5 className={`mt0 ${s?.itemTitle}`}>{title}</h5>
                        <p className='mb0'>{content}</p>
                        {/* {!!cta && <div>
                            <a class={`button uppercase-unset cta`} {...cta} rel="noopener">
                                {cta?.text}
                            </a>
                        </div>} */}
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default CompatibleIndoorUnit