import s from './FeatureAndBenefits.module.scss'

const FeatureAndBenefits = ({ title, benefits }) => {
    console.log({benefits})
    return <div className="feature-and-benefits">
        <h2 className={s.sectionTitle}>{title}</h2>
        <div className={s.grid}>
            {benefits?.map((b, i) => <div>
                <h4 className='mt0'>{b.fields.title}</h4>    
                <p className='mb0' dangerouslySetInnerHTML={{ __html: b.fields.description }}/>
            </div>)}
        </div>
    </div>
}

export default FeatureAndBenefits